import React from 'react'
import BreadCrumb from './BreadCrumb';
import ShopArea from './ShopArea';
import FooterThree from '../../components/footer/FooterThree';
import HeaderThree from '../../components/header/HeaderThree';


function Shop() {
  return (
	<>
	 <HeaderThree/>
     <main>
	 	<BreadCrumb/>
		<ShopArea/>
	 </main>
	 <FooterThree/>
	</>
  )
}

export default Shop