import React from 'react'
import Breadcrumb from "./Breadcrumb";
import CommunityArea from "./CommunityArea"
import FooterThree from '../../components/footer/FooterThree';
import HeaderThree from '../../components/header/HeaderThree';

function Community() {
  return (
	<>
	<HeaderThree/>
	<main>
	<Breadcrumb/>
	<CommunityArea/>
	</main>
	<FooterThree/>
	</>
  )
}

export default Community