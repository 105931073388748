import React from 'react';
import Breadcrumb from './Breadcrumb';
import GameSingleArea from './GameSingleArea';
import HeaderThree from '../../components/header/HeaderThree';
import FooterThree from '../../components/footer/FooterThree';

function Overview() {
  return (
	<>
		<HeaderThree/>
		<main>
			<Breadcrumb/>
			<GameSingleArea/>

		</main>
		<FooterThree/>
	</>
  )
}

export default Overview