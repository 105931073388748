import React from 'react'
import { Link } from 'react-router-dom'

function FooterThree() {
  return (

    <footer>
      <div className="footer-top footer-bg third-footer-bg">
        <div className="container custom-container">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <div className="footer-widget mb-50">
                <div className="fw-text">
                  <h3>Big Corgi Development OÜ</h3>
                  <div className="footer-text">
                    <p>Big Corgi Development OÜ, Rwanda, Reg Certificate 120664886</p>
                    <div className="footer-contact">
                      <ul>
                        <li><i className="fas fa-map-marker-alt" /> <span>Address : </span>1.1.23 Norrsken house, Kigali KN 78 St
                          Kigali, Rwanda.</li>
                        {/* <li><i className="fas fa-headphones" /> <span>Phone : </span>+24 1245 654 235</li> */}
                        <li><i className="fas fa-envelope-open" /><span>Email : </span>info@play.africa</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-5">
              <div className="footer-widget text-right mb-50">
                <div className="fw-title mb-35">
                  <h3>Quick <span>Link</span></h3>
                </div>
                <div className="fw-quick-link">
                  <ul>
                    <li><a href="/#">About Us</a></li>
                    <li><a href="/#">Privacy Policy</a></li>
                    <li><a href="/#">Our Gallery</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-wrap third-copyright-wrap">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="copyright-text">
                <p>Copyright © 2022 <Link to="/">Play.Africa</Link> All Rights Reserved.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 d-none d-md-block">
              <div className="payment-method-img text-right">
                <img src="assets/img/images/card_img.png" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterThree