import React from 'react'
import Breadcrumb from './Breadcrumb';
import ContactArea from './ContactArea';
import Brand from '../../components/index/indexone/Brand'
import HeaderThree from '../../components/header/HeaderThree';
import FooterThree from '../../components/footer/FooterThree';


function Contact() {
  return (
	<>
	<HeaderThree/>
	<main>

	<Breadcrumb/>
	<ContactArea/>
	<Brand/>
	</main>
	<FooterThree/>
	</>
  )
}

export default Contact